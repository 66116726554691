import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

document.addEventListener("DOMContentLoaded", function () {

    var fullWidthSliders = document.querySelectorAll('.full-width-slider')

    //alert('hey')

    fullWidthSliders.forEach((obj, index) => {
        const fullWidthSLider = new Swiper(obj.querySelector('.slider-container'), {
            on: {
                init: function () {
                    if ( fullWidthSLider.slides.length <= 1 ) {
                        this.allowSlidePrev = this.allowSlideNext = false;
                        //this.el.querySelector(".full-width-slider .slider-controls").setAttribute('hidden', '');
                    }
                }
            },
            direction: 'horizontal',
            loop: true,
            watchOverflow: true,
            pagination: {
                el: obj.querySelector('.slider-pagination'),
                type: 'fraction',
            },
            navigation: {
                nextEl: obj.querySelector('.next'),
                prevEl: obj.querySelector('.prev'),
            },
        })
    })
})